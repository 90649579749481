// import request from '../../../../utils/request';
import BasePage from '../../../../components/page_base';

export default {
  name: 'icnoManage',
  extends: BasePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  methods: {
    setColumn(col) {
      const rowData = col;
      if (rowData.field === 'iconStyle') {
        rowData.type = 'html';
        rowData.formatter = ({ row }) => `<i class='${row.iconStyle}'>`;
      }
      return rowData;
    },
    /**
     * @override
     * 可根据字段field 设置列属性
     * @returns {any}
     * @param field
     */
    setFormField(field) {
      if (this.modalConfig.config.code === 'edit') {
        this.modalConfig.title = '编辑';
      } else {
        this.modalConfig.title = '新增';
      }
      return { ...field };
    },
  },
};
